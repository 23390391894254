const exampleStore = {
  namespaced: true, // namespacing reason -> https://stackoverflow.com/questions/47792212/what-exactly-is-namespacing-of-modules-in-vuex
  state: {
    stateFromExampleStore: "",
  },
  getters: {},
  mutations: {},
  actions: {},
};
export default exampleStore;
